import { SVGAssets } from '@business/assets/vectors';
import React from 'react';

interface ZipCodeItemProps {
  zipCodes?: string;
  onClose?: () => void;
  isLocalAreaCard?: boolean;
  timeZone?: string;
  primaryCity?: string;
  isList?: boolean;
  isDetail?: boolean;
  isEditable?: boolean;
}

const ZipCodeItem: React.FC<ZipCodeItemProps> = ({
  zipCodes,
  onClose,
  isLocalAreaCard = false,
  timeZone,
  primaryCity,
  isEditable
}) => {
  const isTooltipNeeded = primaryCity && primaryCity.length > 10;
  const isToolTipTimeZone = timeZone && timeZone.length > 10;

  return (
    <div
      className={`${isLocalAreaCard ? 'relative' : 'w-20 flex items-center justify-between bg-[var(--na-primary-color-hover)] text-white p-1.5 rounded-full relative'} `}>
      {
        // !isLocalAreaCard && !isList && !isDetail
        isEditable && (
          <SVGAssets.CloseButtonRounded
            className="w-4 h-4 cursor-pointer color-primary absolute right-0 zipcode-close"
            data-testid="close-button"
            onClick={onClose}
          />
        )
      }
      {isLocalAreaCard ? (
        <div className=" bg-white local-area-zip min-w-28 cursor-pointer timezone-card h-20">
          <p className="text-xs" title={isTooltipNeeded ? primaryCity : ''}>
            {isTooltipNeeded
              ? `${primaryCity.slice(0, 10)}...`
              : (primaryCity ?? '--')}
          </p>
          <div className="w-16 flex items-center  justify-center bg-[var(--na-primary-color-hover)] text-white p-1.5 rounded-full border border-[var(--na-primary-color)] relative">
            <span
              className={`text-xs pl-1 font-bold overflow-hidden whitespace-nowrap text-ellipsis ${zipCodes && zipCodes.length > 6 ? 'tooltip' : ''}`}
              data-testid="zip-code">
              {zipCodes}
            </span>
          </div>
          <p className="text-xs" title={isToolTipTimeZone ? timeZone : ''}>
            {isToolTipTimeZone
              ? `${timeZone.slice(0, 10)}...`
              : (timeZone ?? '--')}
          </p>
        </div>
      ) : (
        <>
          <span
            className={`text-xs pl-1 overflow-hidden whitespace-nowrap cursor-pointer text-ellipsis ${zipCodes && zipCodes.length > 6 ? 'tooltip' : ''}`}
            data-testid="zip-code">
            {zipCodes}
          </span>
        </>
      )}
      {zipCodes && zipCodes.length > 6 && (
        <div className="tooltip-content absolute bg-black text-white text-xs p-1 rounded mt-1 left-1/2 transform -translate-x-1/2">
          {zipCodes}
        </div>
      )}
    </div>
  );
};

export default ZipCodeItem;
