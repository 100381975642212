import React, { lazy, Suspense, useEffect } from 'react';
import 'aws-amplify/auth/enable-oauth-listener';
import '@business/assets/css/variables.css';
import '@business/assets/css/theme.css';
import '@business/assets/css/schedule.css';
import '@business/assets/css/login-styles.css';
import '@business/assets/css/header.css';
import '@business/assets/css/profile.css';
import '@business/assets/css/content-management.css';
import '@business/assets/css/area-creation.css';
import '@business/assets/css/meal-request.css';
import '@business/assets/css/download-center.css';
import '@business/assets/css/dashboard-insights.css';
import 'react-phone-number-input/style.css';
import { Amplify } from 'aws-amplify';
import { getMessaging, onMessage } from 'firebase/messaging';
import firebaseApp from '@config/firebase';
import { ComponentProvider } from '@business/workflows/ComponentHigherOrder';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import CreateState from '@business/workflows/ComponentViews/StateCreation/CreateState';
import { AppLoaderLay } from '@business/workflows/ComponentUI/AppLoaderLazy';
import { ErrorBoundary } from 'react-error-boundary';
import amplifyconfig from './amplifyconfiguration.json';
import DashboardLayout from './layouts/DashboardLayout';
import AppErrorLayout from './layouts/AppErrorLayout';
import BaseAppLayout from './layouts/BaseAppLayout';
import AuthLayout from './layouts/AuthLayout';

import Page404 from './pages/404/page404';
import AppErrorPage from './pages/AppError/appErrorPage';
import AppPublicLayout from './layouts/AppPublicLayout';

const App = () => {
  Amplify.configure(amplifyconfig);
  useEffect(() => {
    if (typeof window !== 'undefined' && 'serviceWorker' in navigator) {
      const messaging = getMessaging(firebaseApp);
      const unsubscribe = onMessage(messaging, _payload => {});
      return () => {
        unsubscribe();
      };
    }
    return () => {};
  }, [firebaseApp]);
  const AreaComponent = lazy(
    () =>
      import('@business/workflows/ComponentViews/AreaComponent/AreaComponent')
  );
  const AuthComponent = lazy(
    () => import('@business/workflows/ComponentViews/AuthComponent')
  );
  const Contacts = lazy(
    () => import('@business/workflows/ComponentViews/Contacts/Contacts')
  );

  const CountryList = lazy(
    () =>
      import('@business/workflows/ComponentViews/CountryCreation/CountryList')
  );
  const CountryDetails = lazy(
    () =>
      import(
        '@business/workflows/ComponentViews/CountryCreation/CountryDetails'
      )
  );
  const AddNewCountry = lazy(
    () =>
      import('@business/workflows/ComponentViews/CountryCreation/AddNewCountry')
  );
  const CreateAccount = lazy(
    () => import('@business/workflows/ComponentViews/SignUp/CreateAccount')
  );
  const UserCreation = lazy(
    () => import('@business/workflows/ComponentViews/UserCreation/UserCreation')
  );
  const Dashboard = lazy(
    () => import('@business/workflows/ComponentViews/Dashboard/Dashboard')
  );
  const District = lazy(
    () => import('@business/workflows/ComponentViews/District/District')
  );
  const Events = lazy(
    () => import('@business/workflows/ComponentViews/Events/Events')
  );
  const FindMatch = lazy(
    () => import('@business/workflows/ComponentViews/FindMatch/FindMatch')
  );
  const ForgotPassword = lazy(
    () =>
      import('@business/workflows/ComponentViews/ForgotPassword/ForgotPassword')
  );
  const Gallery = lazy(
    () => import('@business/workflows/ComponentViews/Gallery/Gallery')
  );
  const Home = lazy(
    () => import('@business/workflows/ComponentViews/Home/Home')
  );

  const DashboardGuard = lazy(
    () =>
      import(
        '@business/workflows/ComponentHigherOrder/DashboardGuard/DashboardGuard'
      )
  );
  const RequestorInformation = lazy(
    () =>
      import(
        '@business/workflows/ComponentViews/RequestorInformation/RequestorInformation'
      )
  );
  const AreaCreation = lazy(
    () => import('@business/workflows/ComponentViews/AreaCreation/AreaCreation')
  );
  const Matches = lazy(
    () => import('@business/workflows/ComponentViews/Matches/Matches')
  );
  const MyPreferences = lazy(
    () =>
      import('@business/workflows/ComponentViews/MyPreferences/MyPreferences')
  );
  const Profile = lazy(
    () => import('@business/workflows/ComponentViews/Profile/Profile')
  );
  const MealRequests = lazy(
    () => import('@business/workflows/ComponentViews/MealRequests/MealRequests')
  );
  const ResetPassword = lazy(
    () =>
      import('@business/workflows/ComponentViews/ResetPassword/ResetPassword')
  );
  const Resource = lazy(
    () => import('@business/workflows/ComponentViews/HelpAndSupport/resources')
  );
  const Schedules = lazy(
    () => import('@business/workflows/ComponentViews/Schedules/Schedules')
  );
  const SignIn = lazy(
    () => import('@business/workflows/ComponentViews/SignIn/SignIn')
  );
  const SignUpStepOne = lazy(
    () => import('@business/workflows/ComponentViews/SignUp/SignUpStepOne')
  );
  const SignUpComplete = lazy(
    () => import('@business/workflows/ComponentViews/SignUp/SignUpComplete')
  );
  const SignUpStepFour = lazy(
    () => import('@business/workflows/ComponentViews/SignUp/SignUpStepFour')
  );
  const SignUpStepThree = lazy(
    () => import('@business/workflows/ComponentViews/SignUp/SignUpStepThree')
  );
  const SignUpStepTwo = lazy(
    () => import('@business/workflows/ComponentViews/SignUp/SignUpStepTwo')
  );
  const Training = lazy(
    () => import('@business/workflows/ComponentViews/Training/Training')
  );
  const Volunteer = lazy(
    () => import('@business/workflows/ComponentViews/Volunteer/Volunteer')
  );
  const StateList = lazy(
    () => import('@business/workflows/ComponentViews/StateCreation/StateList')
  );
  const StateDetails = lazy(
    () =>
      import('@business/workflows/ComponentViews/StateCreation/StateDetails')
  );
  const UploadStates = lazy(
    () =>
      import('@business/workflows/ComponentViews/StateCreation/UploadStates')
  );
  const LocalArea = lazy(
    () =>
      import('@business/workflows/ComponentViews/AreaComponent/LocalAreaList')
  );

  const LocalAreaDistrictList = lazy(
    () =>
      import('@business/workflows/ComponentViews/ZipCode/localAreaDistrictList')
  );

  const LocalAreaAssignment = lazy(
    () =>
      import('@business/workflows/ComponentViews/ZipCode/localAreaAssignment')
  );

  const LocalAreaDetails = lazy(
    () =>
      import(
        '@business/workflows/ComponentViews/AreaComponent/LocalAreaDetails'
      )
  );

  const AddLocalArea = lazy(
    () =>
      import('@business/workflows/ComponentViews/AreaComponent/AddLocalArea')
  );

  const UploadZipCode = lazy(
    () => import('@business/workflows/ComponentViews/ZipCode/uploadZipCode')
  );
  const ZipCodeListPage = lazy(
    () => import('@business/workflows/ComponentViews/ZipCode/zipCodeListPage')
  );

  const Faq = lazy(
    () => import('@business/workflows/ComponentViews/Faq/index')
  );
  const AddMeal = lazy(
    () => import('@business/workflows/ComponentViews/MealRequest/addMeal')
  );
  const ManageResource = lazy(
    () =>
      import('@business/workflows/ComponentViews/HelpAndSupport/manageResource')
  );
  const ManageFaq = lazy(
    () => import('@business/workflows/ComponentViews/Faq/manageFaq')
  );
  const VolunteerMatches = lazy(
    () =>
      import(
        '@business/workflows/ComponentViews/Matches/Volunteer/VolunteerMatches'
      )
  );
  const ExportRequestList = lazy(
    () =>
      import(
        '@business/workflows/ComponentViews/DownloadCenter/ExportRequestList'
      )
  );

  const UserVolunteerList = lazy(
    () =>
      import('@business/workflows/ComponentViews/Volunteer/UserVolunteerList')
  );

  const DeletedVolunteerList = lazy(
    () => import('@business/workflows/ComponentViews/Volunteer/DeletedUserList')
  );

  const ForceResetPassword = lazy(
    () =>
      import(
        '@business/workflows/ComponentViews/ForceResetPassword/ForceResetPassword'
      )
  );

  const AddVolunteer = lazy(
    () => import('@business/workflows/ComponentViews/Volunteer/AddVolunteer')
  );

  const VolunteerPending = lazy(
    () => import('@business/workflows/ComponentViews/Volunteer/PendingUserList')
  );

  const RoleChangeRequest = lazy(
    () =>
      import(
        '@business/workflows/ComponentViews/Volunteer/RoleChangeRequestList'
      )
  );

  const VolunteerPaused = lazy(
    () =>
      import('@business/workflows/ComponentViews/Volunteer/PausedVolunteerList')
  );

  const VolunteerProfileComponents = lazy(
    () =>
      import(
        '@business/workflows/ComponentViews/Volunteer/VolunteerProfileComponents'
      )
  );

  const ManualMatchList = lazy(
    () => import('@business/workflows/ComponentViews/ManualMatch')
  );

  const LeaderMatchingMap = lazy(
    () => import('@business/workflows/ComponentViews/MatchingMap')
  );
  const MatchingControlPanel = lazy(
    () => import('@business/workflows/ComponentViews/Settings')
  );
  const OptimizationControl = lazy(
    () =>
      import('@business/workflows/ComponentViews/Settings/OptimizationControl')
  );

  const ManageZipcodesRegex = lazy(
    () =>
      import('@business/workflows/ComponentViews/AdminManagement/ZipcodesRegex')
  );

  const BackgroundStats = lazy(
    () =>
      import(
        '@business/workflows/ComponentViews/AdminManagement/BackgroundStats'
      )
  );

  const SchedulerList = lazy(
    () =>
      import('@business/workflows/ComponentViews/AdminManagement/SchedulerList')
  );

  const RolePermissionList = lazy(
    () =>
      import(
        '@business/workflows/ComponentViews/Settings/RolesPermission/RolesPermissionList'
      )
  );

  const AddRolePermission = lazy(
    () =>
      import(
        '@business/workflows/ComponentViews/Settings/RolesPermission/AddRolePermission'
      )
  );

  const PrivacyPolicy = lazy(
    () => import('@business/workflows/ComponentUI/PrivacyPolicy')
  );
  const pageRouters = [
    {
      path: 'area-creation',
      element: <AreaComponent />,
      layout: 'base'
    },
    {
      path: 'auth-flow',
      element: <AuthComponent />,
      layout: 'base'
    },
    {
      path: 'contacts',
      element: <Contacts />,
      layout: 'base'
    },
    {
      path: 'country',
      element: <CountryList />,
      layout: 'base'
    },
    {
      path: 'country/view-more',
      element: <CountryDetails />,
      layout: 'base'
    },
    {
      path: 'country/add-country',
      element: <AddNewCountry />,
      layout: 'base'
    },
    {
      path: 'create-account',
      element: <CreateAccount />,
      layout: 'auth'
    },
    {
      path: 'create-user',
      element: <UserCreation />,
      layout: 'base'
    },
    {
      path: 'dashboard',
      element: <DashboardGuard />,
      layout: 'dashboard'
    },
    {
      path: 'district',
      element: <District />,
      layout: 'base'
    },
    {
      path: 'events',
      element: <Events />,
      layout: 'base'
    },
    {
      path: 'find-match',
      element: <FindMatch />,
      layout: 'base'
    },
    {
      path: 'forgot-password',
      element: <ForgotPassword />,
      layout: 'auth'
    },
    {
      path: 'gallery',
      element: <Gallery />,
      layout: 'base'
    },
    {
      path: 'home-old',
      element: <Home />,
      layout: 'dashboard'
    },
    {
      path: 'home',
      element: <Dashboard />,
      layout: 'dashboard'
    },

    {
      path: 'information',
      element: <RequestorInformation isEdit />,
      layout: 'base'
    },
    {
      path: 'local-area-creation',
      element: <AreaCreation />,
      layout: 'base'
    },
    {
      path: 'matches',
      element: <Matches />,
      layout: 'dashboard'
    },
    {
      path: 'preference',
      element: <MyPreferences />,
      layout: 'base'
    },
    {
      path: 'profile',
      element: <Profile />,
      layout: 'base'
    },
    {
      path: 'request',
      element: <MealRequests />,
      layout: 'base'
    },
    {
      path: 'reset-password',
      element: <ResetPassword />,
      layout: 'auth'
    },
    {
      path: 'help-and-support',
      element: <Resource />,
      layout: 'base'
    },
    {
      path: 'schedules',
      element: <Schedules />,
      layout: 'base'
    },
    {
      path: 'sign-in',
      element: <SignIn />,
      layout: 'auth'
    },
    {
      path: 'sign-up',
      element: <SignUpStepOne />,
      layout: 'auth'
    },
    {
      path: 'sign-up/complete',
      element: <SignUpComplete />,
      layout: 'auth'
    },
    {
      path: 'sign-up/step-four',
      element: <SignUpStepFour />,
      layout: 'auth'
    },
    {
      path: 'sign-up/step-three',
      element: <SignUpStepThree />,
      layout: 'auth'
    },
    {
      path: 'sign-up/step-two',
      element: <SignUpStepTwo />,
      layout: 'auth'
    },
    {
      path: 'training',
      element: <Training />,
      layout: 'base'
    },
    {
      path: 'volunteer',
      element: <Volunteer />,
      layout: 'base'
    },
    {
      path: '/',
      element: <SignIn />,
      layout: 'auth'
    },
    {
      path: 'states',
      element: <StateList />,
      layout: 'base'
    },
    {
      path: 'states/upload-states',
      element: <UploadStates />,
      layout: 'base'
    },
    {
      path: 'states/view-more',
      element: <StateDetails />,
      layout: 'base'
    },
    {
      path: 'states/create-states',
      element: <CreateState />,
      layout: 'base'
    },

    {
      path: 'local-area',
      element: <LocalArea />,
      layout: 'base'
    },
    {
      path: 'zip-code/upload',
      element: <UploadZipCode />,
      layout: 'base'
    },
    {
      path: '/local-area/view-more',
      element: <LocalAreaDetails />,
      layout: 'base'
    },
    {
      path: '/local-area/add',
      element: <AddLocalArea />,
      layout: 'base'
    },
    {
      path: 'zip-code/list',
      element: <ZipCodeListPage />,
      layout: 'base'
    },
    {
      path: '*',
      element: <Page404 />,
      layout: 'error'
    },
    {
      path: 'faq',
      element: <Faq />,
      layout: 'base'
    },
    {
      path: 'add-meal',
      element: <AddMeal />,
      layout: 'public'
    },
    {
      path: 'manage-resource',
      element: <ManageResource />,
      layout: 'base'
    },
    {
      path: 'list-faq',
      element: <ManageFaq />,
      layout: 'base'
    },
    {
      path: 'volunteer-matches',
      element: <VolunteerMatches />,
      layout: 'dashboard'
    },
    {
      path: 'download-center',
      element: <ExportRequestList />,
      layout: 'base'
    },
    {
      path: 'volunteer/list',
      element: <UserVolunteerList />,
      layout: 'base'
    },
    {
      path: 'force-reset-password',
      element: <ForceResetPassword />,
      layout: 'auth'
    },

    {
      path: 'volunteer/add',
      element: <AddVolunteer />,
      layout: 'base'
    },

    {
      path: 'volunteer/list',
      element: <UserVolunteerList />,
      layout: 'base'
    },

    {
      path: 'volunteer/deleted-list',
      element: <DeletedVolunteerList />,
      layout: 'base'
    },
    {
      path: 'volunteer/pending-list',
      element: <VolunteerPending />,
      layout: 'base'
    },
    {
      path: 'volunteer/paused-list',
      element: <VolunteerPaused />,
      layout: 'base'
    },
    {
      path: 'volunteer/profile',
      element: <VolunteerProfileComponents />,
      layout: 'base'
    },
    {
      path: 'local-area/list',
      element: <LocalAreaDistrictList />,
      layout: 'base'
    },
    {
      path: 'local-area/assignment',
      element: <LocalAreaAssignment />,
      layout: 'base'
    },
    {
      path: 'manual-match',
      element: <ManualMatchList />,
      layout: 'dashboard'
    },
    {
      path: 'meal-request-matching',
      element: <ManualMatchList />,
      layout: 'dashboard'
    },
    {
      path: 'leader-matching-map',
      element: <LeaderMatchingMap />,
      layout: 'dashboard'
    },
    {
      path: 'matching-control',
      element: <MatchingControlPanel />,
      layout: 'base'
    },
    {
      path: 'optimization-control',
      element: <OptimizationControl />,
      layout: 'base'
    },
    {
      path: 'manage-zipcodes',
      element: <ManageZipcodesRegex />,
      layout: 'base'
    },
    {
      path: 'background-stats',
      element: <BackgroundStats />,
      layout: 'base'
    },
    {
      path: 'scheduler-list',
      element: <SchedulerList />,
      layout: 'base'
    },
    {
      path: 'role-permission-list',
      element: <RolePermissionList />,
      layout: 'base'
    },
    {
      path: 'privacy-policy',
      element: <PrivacyPolicy />,
      layout: 'public'
    },
    {
      path: 'add-role-permission',
      element: <AddRolePermission />,
      layout: 'base'
    },
    {
      path: 'volunteer/user-requests',
      element: <RoleChangeRequest />,
      layout: 'base'
    }
  ];

  const fallbackRender = (_error: any) => {
    return (
      <AppErrorLayout>
        <AppErrorPage />
      </AppErrorLayout>
    );
  };

  const convertComponent = (routerInfo: any) => {
    switch (routerInfo?.layout) {
      case 'error':
        return <AppErrorLayout>{routerInfo?.element}</AppErrorLayout>;
      case 'dashboard':
        return <DashboardLayout>{routerInfo?.element}</DashboardLayout>;
      case 'auth':
        return <AuthLayout>{routerInfo?.element}</AuthLayout>;
      case 'public':
        return <AppPublicLayout>{routerInfo?.element}</AppPublicLayout>;
      default:
        return <BaseAppLayout>{routerInfo?.element}</BaseAppLayout>;
    }
  };

  return (
    <ComponentProvider>
      <BrowserRouter>
        {/* <HistoryRouter history={history}> */}
        <ErrorBoundary
          fallbackRender={fallbackRender}
          onReset={() => (location.href = '/')}>
          <Suspense fallback={<AppLoaderLay />}>
            <Routes>
              {pageRouters.map(routeObj => (
                <Route
                  path={routeObj?.path}
                  element={convertComponent(routeObj)}
                  key={routeObj?.path}
                />
              ))}
            </Routes>
          </Suspense>
        </ErrorBoundary>
      </BrowserRouter>
      {/* </HistoryRouter> */}
    </ComponentProvider>
  );
};

export default App;
