import { createSlice } from '@reduxjs/toolkit';
import type { RootState } from './store';

type RoleData = {
  id: string;
  name: string;
  isAdd: boolean;
};

const initialState: RoleData = {
  id: '',
  name: '',
  isAdd: false
};

export const groupsStateSlice = createSlice({
  name: 'groupsStateSlice',
  initialState,
  reducers: {
    setRoleData: (state, action) => {
      return { ...state, ...action.payload };
    },
    resetRoleDetails: () => {
      return initialState;
    }
  }
});

export const { setRoleData, resetRoleDetails } = groupsStateSlice.actions;
export const getRoleData = (state: RootState) => state.groupsStateSlice;
