import { createSlice } from '@reduxjs/toolkit';
import type { RootState } from './store';

export type RoleProps = {
  role: {
    id: string;
    label: string;
  };
};

const initialState: RoleProps = {
  role: {
    id: '',
    label: ''
  }
};

export const rolePermissionStateSlice = createSlice({
  name: 'rolePermissionStateSlice',
  initialState,
  reducers: {
    setRole: (state, action) => {
      state.role = action.payload;
    }
  }
});

export const { setRole } = rolePermissionStateSlice.actions;
export const getRole = (state: RootState) =>
  state.rolePermissionStateSlice.role;
