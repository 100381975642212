import { captchaApi as api } from './captchaApi';
export const addTagTypes = ['user'] as const;
const injectedRtkApi = api
  .enhanceEndpoints({
    addTagTypes
  })
  .injectEndpoints({
    endpoints: build => ({
      userCaptchaCreate: build.mutation<
        UserCaptchaCreateApiResponse,
        UserCaptchaCreateApiArg
      >({
        query: queryArg => ({
          url: `/user/captcha/`,
          method: 'POST',
          body: queryArg.capcha
        }),
        invalidatesTags: ['user']
      })
    }),
    overrideExisting: false
  });
export { injectedRtkApi as enhancedApi };
export type UserCaptchaCreateApiResponse = /** status 200 Custom Response */ {
  count?: number;
  next?: string | null;
  previous?: string | null;
  results: Capcha[];
  ok?: boolean;
  message?: {
    text?: string | null;
    code?: number;
    args?: object | null;
  };
};
export type UserCaptchaCreateApiArg = {
  capcha: Capcha;
};
export type Capcha = {
  token: string;
};
export const { useUserCaptchaCreateMutation } = injectedRtkApi;
