import { createSlice } from '@reduxjs/toolkit';
import type { RootState } from './store';

export type authStateType = {
  forgotPasswordUser: '';
  createUserEmail: '';
  isResetPasswordSuccess: boolean;
  loginType: string;
  notifyData: {
    id: string;
    data: string;
  };
  confirmSignIn: boolean;
  signUpUserId: string;
  rememberMe: boolean;
  profileUpdate: boolean;
};

const initialState: authStateType = {
  forgotPasswordUser: '',
  createUserEmail: '',
  isResetPasswordSuccess: false,
  loginType: '',
  notifyData: {
    id: '',
    data: ''
  },
  confirmSignIn: false,
  signUpUserId: '',
  rememberMe: true,
  profileUpdate: false
};

export const authStateSlice = createSlice({
  name: 'authStateSlice',
  initialState,
  reducers: {
    setUsername: (state, action) => {
      state.forgotPasswordUser = action.payload.forgotPasswordUser;
      return state;
    },
    setCreateUserEmail: (state, action) => {
      state.createUserEmail = action.payload.createUserEmail;
      return state;
    },
    setResetPasswordSuccess: (state, action) => {
      state.isResetPasswordSuccess = action.payload.isResetPasswordSuccess;
      return state;
    },
    setLoginType: (state, action) => {
      state.loginType = action.payload.loginType;
      return;
    },
    setNotifyData: (state, action) => {
      state.notifyData = action.payload.notifyData;
      return;
    },
    setConfirmSignIn: (state, action) => {
      state.confirmSignIn = action.payload.confirmSignIn;
      return;
    },
    setSignUpUserId: (state, action) => {
      state.signUpUserId = action.payload.signUpUserId;
      return;
    },
    setRememberMe: (state, action) => {
      state.rememberMe = action.payload.rememberMe;
    },
    setProfileUpdate: (state, action) => {
      state.profileUpdate = action.payload.profileUpdate;
    },
    resetAuthState: () => {
      return initialState;
    }
  }
});

export const {
  setUsername,
  setCreateUserEmail,
  setResetPasswordSuccess,
  setLoginType,
  setNotifyData,
  setConfirmSignIn,
  setSignUpUserId,
  setRememberMe,
  setProfileUpdate,
  resetAuthState
} = authStateSlice.actions;

export const getUsername = (state: RootState) =>
  state.authStateSlice.forgotPasswordUser;
export const getCreateUserEmail = (state: RootState) =>
  state.authStateSlice.createUserEmail;
export const getResetPasswordSuccess = (state: RootState) =>
  state.authStateSlice.isResetPasswordSuccess;
export const getLoginType = (state: RootState) =>
  state.authStateSlice.loginType;
export const getNotifyData = (state: RootState) =>
  state.authStateSlice.notifyData;
export const getConfirmSignIn = (state: RootState) =>
  state.authStateSlice.confirmSignIn;
export const getSignUpUserId = (state: RootState) =>
  state.authStateSlice.signUpUserId;
export const getRememberMe = (state: RootState) =>
  state.authStateSlice.rememberMe;
export const getProfileUpdate = (state: RootState) =>
  state.authStateSlice.profileUpdate;
