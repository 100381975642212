import { manualMatchingApi as api } from './manualMatchingApi';
export const addTagTypes = ['matching', 'user'] as const;
const injectedRtkApi = api
  .enhanceEndpoints({
    addTagTypes
  })
  .injectEndpoints({
    endpoints: build => ({
      matchingBackgroundStatsList: build.query<
        MatchingBackgroundStatsListApiResponse,
        MatchingBackgroundStatsListApiArg
      >({
        query: queryArg => ({
          url: `/matching/background_stats/`,
          params: {
            search: queryArg.search,
            ordering: queryArg.ordering,
            limit: queryArg.limit,
            offset: queryArg.offset
          }
        }),
        providesTags: ['matching']
      }),
      matchingMealRequestBulkUpdateRequests: build.mutation<
        MatchingMealRequestBulkUpdateRequestsApiResponse,
        MatchingMealRequestBulkUpdateRequestsApiArg
      >({
        query: queryArg => ({
          url: `/matching/meal_request/bulk_update_requests/`,
          method: 'POST',
          body: queryArg.bulkUpdateRequests
        }),
        invalidatesTags: ['matching']
      }),
      matchingMealRequestMatchedList: build.query<
        MatchingMealRequestMatchedListApiResponse,
        MatchingMealRequestMatchedListApiArg
      >({
        query: queryArg => ({
          url: `/matching/meal_request/matched_list/`,
          params: {
            search: queryArg.search,
            ordering: queryArg.ordering,
            limit: queryArg.limit,
            offset: queryArg.offset
          }
        }),
        providesTags: ['matching']
      }),
      matchingMealRequestMatchedUnmatchedRequests: build.query<
        MatchingMealRequestMatchedUnmatchedRequestsApiResponse,
        MatchingMealRequestMatchedUnmatchedRequestsApiArg
      >({
        query: queryArg => ({
          url: `/matching/meal_request/matched_unmatched_requests/`,
          params: {
            search: queryArg.search,
            ordering: queryArg.ordering,
            limit: queryArg.limit,
            offset: queryArg.offset,
            r_type: queryArg.rType
          }
        }),
        providesTags: ['matching']
      }),
      matchingMealRequestMyMatches: build.query<
        MatchingMealRequestMyMatchesApiResponse,
        MatchingMealRequestMyMatchesApiArg
      >({
        query: queryArg => ({
          url: `/matching/meal_request/my_matches/`,
          params: {
            search: queryArg.search,
            ordering: queryArg.ordering,
            limit: queryArg.limit,
            offset: queryArg.offset,
            status: queryArg.status,
            r_type: queryArg.rType
          }
        }),
        providesTags: ['matching']
      }),
      matchingMealRequestMatchUnmatchVolunteer: build.mutation<
        MatchingMealRequestMatchUnmatchVolunteerApiResponse,
        MatchingMealRequestMatchUnmatchVolunteerApiArg
      >({
        query: queryArg => ({
          url: `/matching/meal_request/${queryArg.uuid}/match_unmatch_volunteer/`,
          method: 'PATCH',
          body: queryArg.matchUnmatch
        }),
        invalidatesTags: ['matching']
      }),
      matchingTotalMyMatchesList: build.query<
        MatchingTotalMyMatchesListApiResponse,
        MatchingTotalMyMatchesListApiArg
      >({
        query: queryArg => ({
          url: `/matching/total_my_matches/`,
          params: {
            search: queryArg.search,
            ordering: queryArg.ordering,
            limit: queryArg.limit,
            offset: queryArg.offset
          }
        }),
        providesTags: ['matching']
      }),
      userUsersBulkUpdateVolunteerStatus: build.mutation<
        UserUsersBulkUpdateVolunteerStatusApiResponse,
        UserUsersBulkUpdateVolunteerStatusApiArg
      >({
        query: queryArg => ({
          url: `/user/users/bulk_update_volunteer_status/`,
          method: 'POST',
          body: queryArg.bulkUpdateVolunteer
        }),
        invalidatesTags: ['user']
      }),
      userUsersSentNomatchVolunteer: build.mutation<
        UserUsersSentNomatchVolunteerApiResponse,
        UserUsersSentNomatchVolunteerApiArg
      >({
        query: queryArg => ({
          url: `/user/users/sent_nomatch_volunteer/`,
          method: 'POST',
          body: queryArg.body
        }),
        invalidatesTags: ['user']
      }),
      userUsersUpdateLocalLeader: build.mutation<
        UserUsersUpdateLocalLeaderApiResponse,
        UserUsersUpdateLocalLeaderApiArg
      >({
        query: queryArg => ({
          url: `/user/users/update_local_leader/`,
          method: 'POST',
          body: queryArg.otherDetailsProfile
        }),
        invalidatesTags: ['user']
      }),
      userUsersVolunteerListMatches: build.query<
        UserUsersVolunteerListMatchesApiResponse,
        UserUsersVolunteerListMatchesApiArg
      >({
        query: queryArg => ({
          url: `/user/users/volunteer_list_matches/`,
          params: {
            search: queryArg.search,
            ordering: queryArg.ordering,
            limit: queryArg.limit,
            offset: queryArg.offset,
            status: queryArg.status,
            requester: queryArg.requester,
            district__uuid: queryArg.districtUuid
          }
        }),
        providesTags: ['user']
      }),
      userUsersPartialUpdate: build.mutation<
        UserUsersPartialUpdateApiResponse,
        UserUsersPartialUpdateApiArg
      >({
        query: queryArg => ({
          url: `/user/users/${queryArg.uuid}/`,
          method: 'PATCH',
          body: queryArg.user
        }),
        invalidatesTags: ['user']
      })
    }),
    overrideExisting: false
  });
export { injectedRtkApi as enhancedApi };
export type MatchingBackgroundStatsListApiResponse =
  /** status 200 Custom Response */ {
    count?: number;
    next?: string | null;
    previous?: string | null;
    results: RequestStatistics[];
    ok?: boolean;
    message?: {
      text?: string | null;
      code?: number;
      args?: object | null;
    };
  };
export type MatchingBackgroundStatsListApiArg = {
  /** A search term. */
  search?: string;
  /** Which field to use when ordering the results. */
  ordering?: string;
  /** Number of results to return per page. */
  limit?: number;
  /** The initial index from which to return the results. */
  offset?: number;
};
export type MatchingMealRequestBulkUpdateRequestsApiResponse =
  /** status 200 Custom Response */ {
    count?: number;
    next?: string | null;
    previous?: string | null;
    results: MealRequestRead[];
    ok?: boolean;
    message?: {
      text?: string | null;
      code?: number;
      args?: object | null;
    };
  };
export type MatchingMealRequestBulkUpdateRequestsApiArg = {
  bulkUpdateRequests: BulkUpdateRequests;
};
export type MatchingMealRequestMatchedListApiResponse =
  /** status 200 Custom Response */ {
    count?: number;
    next?: string | null;
    previous?: string | null;
    results: MealRequestRead[];
    ok?: boolean;
    message?: {
      text?: string | null;
      code?: number;
      args?: object | null;
    };
  };
export type MatchingMealRequestMatchedListApiArg = {
  /** A search term. */
  search?: string;
  /** Which field to use when ordering the results. */
  ordering?: string;
  /** Number of results to return per page. */
  limit?: number;
  /** The initial index from which to return the results. */
  offset?: number;
};
export type MatchingMealRequestMatchedUnmatchedRequestsApiResponse =
  /** status 200 Custom Response */ {
    count?: number;
    next?: string | null;
    previous?: string | null;
    results: MealRequestRead[];
    ok?: boolean;
    message?: {
      text?: string | null;
      code?: number;
      args?: object | null;
    };
  };
export type MatchingMealRequestMatchedUnmatchedRequestsApiArg = {
  /** A search term. */
  search?: string;
  /** Which field to use when ordering the results. */
  ordering?: string;
  /** Number of results to return per page. */
  limit?: number;
  /** The initial index from which to return the results. */
  offset?: number;
  rType?: 'matched' | 'unmatched' | 'approved';
};
export type MatchingMealRequestMyMatchesApiResponse =
  /** status 200 Custom Response */ {
    count?: number;
    next?: string | null;
    previous?: string | null;
    results: MealRequestRead[];
    ok?: boolean;
    message?: {
      text?: string | null;
      code?: number;
      args?: object | null;
    };
  };
export type MatchingMealRequestMyMatchesApiArg = {
  /** A search term. */
  search?: string;
  /** Which field to use when ordering the results. */
  ordering?: string;
  /** Number of results to return per page. */
  limit?: number;
  /** The initial index from which to return the results. */
  offset?: number;
  /** Filter users by status */
  status?:
    | 'schedule'
    | 'scheduled'
    | 'noresponse'
    | 'new'
    | 'delivered'
    | 'contacted'
    | 'cancelled';
  /** Request Type */
  rType?: string;
};
export type MatchingMealRequestMatchUnmatchVolunteerApiResponse =
  /** status 200 Custom Response */ {
    results: MealRequestRead;
    ok?: boolean;
    message?: {
      text?: string | null;
      code?: number;
      args?: object | null;
    };
  };
export type MatchingMealRequestMatchUnmatchVolunteerApiArg = {
  uuid: string;
  matchUnmatch: MatchUnmatch;
};
export type MatchingTotalMyMatchesListApiResponse =
  /** status 200 Custom Response */ {
    count?: number;
    next?: string | null;
    previous?: string | null;
    results: {
      data: TotalMatchesResponse[];
    };
    ok?: boolean;
    message?: {
      text?: string | null;
      code?: number;
      args?: object | null;
    };
  };
export type MatchingTotalMyMatchesListApiArg = {
  /** A search term. */
  search?: string;
  /** Which field to use when ordering the results. */
  ordering?: string;
  /** Number of results to return per page. */
  limit?: number;
  /** The initial index from which to return the results. */
  offset?: number;
};
export type UserUsersBulkUpdateVolunteerStatusApiResponse =
  /** status 200 Custom Response */ {
    count?: number;
    next?: string | null;
    previous?: string | null;
    results: UserRead[];
    ok?: boolean;
    message?: {
      text?: string | null;
      code?: number;
      args?: object | null;
    };
  };
export type UserUsersBulkUpdateVolunteerStatusApiArg = {
  bulkUpdateVolunteer: BulkUpdateVolunteer;
};
export type UserUsersSentNomatchVolunteerApiResponse =
  /** status 200 Custom Response */ {
    count?: number;
    next?: string | null;
    previous?: string | null;
    results: UserRead[];
    ok?: boolean;
    message?: {
      text?: string | null;
      code?: number;
      args?: object | null;
    };
  };
export type UserUsersSentNomatchVolunteerApiArg = {
  body: {
    /** List of user UUIDs to delete */
    user_uuids: string[];
  };
};
export type UserUsersUpdateLocalLeaderApiResponse =
  /** status 200 Custom Response */ {
    count?: number;
    next?: string | null;
    previous?: string | null;
    results: UserRead[];
    ok?: boolean;
    message?: {
      text?: string | null;
      code?: number;
      args?: object | null;
    };
  };
export type UserUsersUpdateLocalLeaderApiArg = {
  otherDetailsProfile: OtherDetailsProfile;
};
export type UserUsersVolunteerListMatchesApiResponse =
  /** status 200 Custom Response */ {
    count?: number;
    next?: string | null;
    previous?: string | null;
    results: UserRead[];
    ok?: boolean;
    message?: {
      text?: string | null;
      code?: number;
      args?: object | null;
    };
  };
export type UserUsersVolunteerListMatchesApiArg = {
  /** A search term. */
  search?: string;
  /** Which field to use when ordering the results. */
  ordering?: string;
  /** Number of results to return per page. */
  limit?: number;
  /** The initial index from which to return the results. */
  offset?: number;
  /** Filter users by status */
  status?: 'paused' | 'active';
  /** For requester to user distance. input requester uuid */
  requester?: string;
  districtUuid?: string;
};
export type UserUsersPartialUpdateApiResponse =
  /** status 200 Custom Response */ {
    results: UserRead;
    ok?: boolean;
    message?: {
      text?: string | null;
      code?: number;
      args?: object | null;
    };
  };
export type UserUsersPartialUpdateApiArg = {
  uuid: string;
  user: User;
};
export type RequestStatistics = {
  requests: number;
  notmatched: number;
  notscheduled: number;
  elevated: number;
  archived: number;
  matches: number;
  deliveries: number;
  notdelivered_remind: number;
  notdelivered_warn: number;
  aborted: number;
  blocked: number;
};
export type ProfileList = {
  gender?: ('M' | 'F' | 'PNTS') | null;
  birth_date?: string | null;
  contact_number?: string | null;
  street_address?: string | null;
  cb_address?: string | null;
  city?: string | null;
  state?: string | null;
  country_origin?: string | null;
  completed_percentage?: number;
  zip_code?: string | null;
  profile_pic?: string;
  cover_pic?: string;
  volunteering_with?: boolean;
  mailing_address_same?: number;
  country_name?: string;
};
export type ProfileListRead = {
  uuid?: string;
  gender?: ('M' | 'F' | 'PNTS') | null;
  birth_date?: string | null;
  over_18?: string;
  contact_number?: string | null;
  street_address?: string | null;
  cb_address?: string | null;
  city?: string | null;
  state?: string | null;
  country_origin?: string | null;
  completed_percentage?: number;
  zip_code?: string | null;
  profile_pic?: string;
  cover_pic?: string;
  volunteering_with?: boolean;
  mailing_address_same?: number;
  country_name?: string;
};
export type MamaProfileLite = {
  cb_mamaavail?: ('Active' | 'Paused') | null;
  cb_mamafreq?: string | null;
  cb_mamacap?: number | null;
  cb_mamafillin?: string | null;
  cb_mamaradiustype?: string | null;
  cb_mamacooks?: string | null;
  cb_mamastart?: string | null;
  cb_mamasmsok?: number | null;
  cb_mamaleader?: string | null;
  cb_mamalat?: string | null;
  cb_mamalon?: string | null;
  cb_mamatzone?: string | null;
  cb_mamamatches?: number | null;
  cb_mamadeliveries?: number | null;
  cb_hidedelivered?: string | null;
};
export type MamaProfileLiteRead = {
  uuid?: string;
  cb_mamaavail?: ('Active' | 'Paused') | null;
  cb_mamafreq?: string | null;
  cb_mamacap?: number | null;
  cb_mamafillin?: string | null;
  cb_mamaradius?: string;
  cb_mamaradiustype?: string | null;
  cb_mamacooks?: string | null;
  cb_mamastart?: string | null;
  cb_mamasmsok?: number | null;
  cb_mamaleader?: string | null;
  cb_mamalat?: string | null;
  cb_mamalon?: string | null;
  cb_mamatzone?: string | null;
  cb_mamamatches?: number | null;
  cb_mamadeliveries?: number | null;
  cb_hidedelivered?: string | null;
};
export type VolunteerDetail = {
  first_name?: string;
  last_name?: string;
  email: string;
  profile: ProfileList;
  mamaprofile?: MamaProfileLite;
};
export type VolunteerDetailRead = {
  uuid?: string;
  first_name?: string;
  last_name?: string;
  email: string;
  profile: ProfileListRead;
  mamaprofile?: MamaProfileLiteRead;
};
export type MealRequest = {
  status?:
    | 'schedule'
    | 'scheduled'
    | 'noresponse'
    | 'new'
    | 'delivered'
    | 'contacted'
    | 'cancelled';
  entrydate?: string | null;
  requestdate?: string | null;
  matchdate?: string | null;
  rdapprovedate?: string | null;
  approvedate?: string | null;
  scheduledate?: string | null;
  deliverydate?: string | null;
  firstname?: string | null;
  lastname?: string | null;
  mobile?: string | null;
  email?: string | null;
  address?: string | null;
  address1?: string | null;
  address2?: string | null;
  city?: string | null;
  state?: string | null;
  zip?: string | null;
  co?: string | null;
  lat?: number | null;
  lon?: number | null;
  timezone?: string | null;
  adults?: number | null;
  kids?: number | null;
  familysize?: number | null;
  about?: string | null;
  restrictions?: boolean;
  vegetarian?: boolean;
  vegan?: boolean;
  glutenfree?: boolean;
  dairyfree?: boolean;
  nutallergy?: boolean;
  otherallergy?: boolean;
  allergyinfo?: string | null;
  goodness?: boolean;
  matchmama?: VolunteerDetail;
  mamanotes?: string | null;
  priority?: number | null;
  latlonkey?: string | null;
  distance?: number | null;
  vetted?: number | null;
  source?: string | null;
  othersource?: string | null;
  matchregion?: string | null;
  matched_with?: string | null;
  latloncheck?: string | null;
  sendemail?: number | null;
  emailprefs?: string | null;
  goodness_carrabba?: string | null;
  country_origin?: string | null;
};
export type MealRequestRead = {
  uuid?: string;
  status?:
    | 'schedule'
    | 'scheduled'
    | 'noresponse'
    | 'new'
    | 'delivered'
    | 'contacted'
    | 'cancelled';
  entrydate?: string | null;
  requestdate?: string | null;
  matchdate?: string | null;
  rdapprovedate?: string | null;
  approvedate?: string | null;
  scheduledate?: string | null;
  deliverydate?: string | null;
  firstname?: string | null;
  lastname?: string | null;
  mobile?: string | null;
  email?: string | null;
  address?: string | null;
  address1?: string | null;
  address2?: string | null;
  city?: string | null;
  state?: string | null;
  zip?: string | null;
  co?: string | null;
  lat?: number | null;
  lon?: number | null;
  timezone?: string | null;
  adults?: number | null;
  kids?: number | null;
  familysize?: number | null;
  about?: string | null;
  restrictions?: boolean;
  vegetarian?: boolean;
  vegan?: boolean;
  glutenfree?: boolean;
  dairyfree?: boolean;
  nutallergy?: boolean;
  otherallergy?: boolean;
  allergyinfo?: string | null;
  goodness?: boolean;
  matchmama?: VolunteerDetailRead;
  mamanotes?: string | null;
  priority?: number | null;
  latlonkey?: string | null;
  distance?: number | null;
  vetted?: number | null;
  source?: string | null;
  othersource?: string | null;
  matchregion?: string | null;
  matched_with?: string | null;
  latloncheck?: string | null;
  sendemail?: number | null;
  emailprefs?: string | null;
  goodness_carrabba?: string | null;
  country_origin?: string | null;
};
export type BulkUpdateRequests = {
  priority: number;
};
export type MatchUnmatch = {
  m_type: string;
  matchmama: string;
};
export type YearMatchData = {
  year: number;
  matches: {
    cancelled?: number;
    contacted?: number;
    delivered?: number;
    new?: number;
    noresponse?: number;
    scheduled?: number;
  };
};
export type TotalMatchesResponse = {
  matches: {
    cancelled?: number;
    contacted?: number;
    delivered?: number;
    new?: number;
    noresponse?: number;
    scheduled?: number;
  };
  year: string;
};
export type GroupLite = {
  name: string;
};
export type GroupLiteRead = {
  uuid?: string;
  name: string;
};
export type OtherDetailsProfile = {
  cb_source?: string | null;
  cb_othersource?: string | null;
  cb_employer?: string | null;
  cb_organization?: string | null;
  cb_watchedvideofood?: number | null;
  cb_dateconfirmed?: string | null;
  cb_dateapproved?: string | null;
  cb_leadermatchingcomplete?: number | null;
  cb_leadersentnomatch?: number | null;
  cb_newmatches?: string | null;
  cb_weeksmatches?: number | null;
};
export type OtherDetailsProfileRead = {
  uuid?: string;
  cb_source?: string | null;
  cb_othersource?: string | null;
  cb_employer?: string | null;
  cb_organization?: string | null;
  cb_watchedvideofood?: number | null;
  cb_dateconfirmed?: string | null;
  cb_dateapproved?: string | null;
  cb_leadermatchingcomplete?: number | null;
  cb_leadersentnomatch?: number | null;
  cb_newmatches?: string | null;
  cb_weeksmatches?: number | null;
};
export type MamaProfile = {
  cb_mamaavail?: ('Active' | 'Paused') | null;
  cb_mamastart?: string | null;
  cb_pausedate?: string | null;
  cb_mamapausenotes?: string | null;
  cb_mamafreq?: string | null;
  cb_mamacap?: number | null;
  cb_mamafillin?: string | null;
  cb_mamaradius?: number | null;
  cb_mamacooks?: string | null;
  cb_mamasmsok?: number | null;
  cb_mamaleader?: string | null;
  cb_mamalat?: string | null;
  cb_mamalon?: string | null;
  cb_mamatzone?: string | null;
  cb_mamamisc?: string | null;
  cb_mamasocial?: string | null;
  cb_mamamatches?: number | null;
  cb_mamadeliveries?: number | null;
  cb_mamalmnotes?: string | null;
  cb_mama1stmatch?: string | null;
  cb_hidedelivered?: string | null;
  cb_mamaradiustype?: string | null;
};
export type MamaProfileRead = {
  uuid?: string;
  cb_mamaavail?: ('Active' | 'Paused') | null;
  cb_mamastart?: string | null;
  cb_pausedate?: string | null;
  cb_mamapausenotes?: string | null;
  cb_mamafreq?: string | null;
  cb_mamacap?: number | null;
  cb_mamafillin?: string | null;
  cb_mamaradius?: number | null;
  cb_mamacooks?: string | null;
  cb_mamasmsok?: number | null;
  cb_mamaleader?: string | null;
  cb_mamalat?: string | null;
  cb_mamalon?: string | null;
  cb_mamatzone?: string | null;
  cb_mamamisc?: string | null;
  cb_mamasocial?: string | null;
  cb_mamamatches?: number | null;
  cb_mamadeliveries?: number | null;
  cb_mamalmnotes?: string | null;
  cb_mama1stmatch?: string | null;
  cb_hidedelivered?: string | null;
  cb_mamaradiustype?: string | null;
};
export type User = {
  first_name?: string;
  last_name?: string;
  email: string;
  profile: ProfileList;
  groups?: GroupLite;
  other_details: OtherDetailsProfile;
  mamaprofile: MamaProfile;
  /** Designates whether this user should be treated as active. Unselect this instead of deleting accounts. */
  is_active?: boolean;
  block?: number;
};
export type UserRead = {
  uuid?: string;
  first_name?: string;
  last_name?: string;
  email: string;
  date_joined?: string;
  profile: ProfileListRead;
  groups?: GroupLiteRead;
  other_details: OtherDetailsProfileRead;
  mamaprofile: MamaProfileRead;
  /** Designates whether this user should be treated as active. Unselect this instead of deleting accounts. */
  is_active?: boolean;
  block?: number;
  last_login?: string | null;
  distance_to_requester?: string;
};
export type BulkUpdateVolunteer = {
  user_uuids?: string[];
  district__uuid?: string;
};
export const {
  useMatchingBackgroundStatsListQuery,
  useMatchingMealRequestBulkUpdateRequestsMutation,
  useMatchingMealRequestMatchedListQuery,
  useMatchingMealRequestMatchedUnmatchedRequestsQuery,
  useMatchingMealRequestMyMatchesQuery,
  useMatchingMealRequestMatchUnmatchVolunteerMutation,
  useMatchingTotalMyMatchesListQuery,
  useUserUsersBulkUpdateVolunteerStatusMutation,
  useUserUsersSentNomatchVolunteerMutation,
  useUserUsersUpdateLocalLeaderMutation,
  useUserUsersVolunteerListMatchesQuery,
  useUserUsersPartialUpdateMutation
} = injectedRtkApi;
