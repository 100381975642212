import { insightsDashboardApi as api } from './insightsDashboardApi';
export const addTagTypes = ['matching', 'user'] as const;
const injectedRtkApi = api
  .enhanceEndpoints({
    addTagTypes
  })
  .injectEndpoints({
    endpoints: build => ({
      matchingAverageWaitTimeBarGraph: build.query<
        MatchingAverageWaitTimeBarGraphApiResponse,
        MatchingAverageWaitTimeBarGraphApiArg
      >({
        query: queryArg => ({
          url: `/matching/average_wait_time/bar_graph/`,
          params: {
            search: queryArg.search,
            ordering: queryArg.ordering,
            limit: queryArg.limit,
            offset: queryArg.offset,
            start_date: queryArg.startDate,
            end_date: queryArg.endDate,
            country: queryArg.country,
            country_id: queryArg.countryId,
            state_id: queryArg.stateId,
            district_id: queryArg.districtId
          }
        }),
        providesTags: ['matching']
      }),
      matchingDeliveryInsightDeliveryStatistics: build.query<
        MatchingDeliveryInsightDeliveryStatisticsApiResponse,
        MatchingDeliveryInsightDeliveryStatisticsApiArg
      >({
        query: queryArg => ({
          url: `/matching/delivery_insight/delivery_statistics/`,
          params: {
            search: queryArg.search,
            ordering: queryArg.ordering,
            limit: queryArg.limit,
            offset: queryArg.offset,
            trend: queryArg.trend,
            start_date: queryArg.startDate,
            end_date: queryArg.endDate,
            country: queryArg.country,
            country_id: queryArg.countryId,
            state_id: queryArg.stateId,
            district_id: queryArg.districtId
          }
        }),
        providesTags: ['matching']
      }),
      matchingDeliveryInsightLocationDelivery: build.query<
        MatchingDeliveryInsightLocationDeliveryApiResponse,
        MatchingDeliveryInsightLocationDeliveryApiArg
      >({
        query: queryArg => ({
          url: `/matching/delivery_insight/location_delivery/`,
          params: {
            search: queryArg.search,
            ordering: queryArg.ordering,
            limit: queryArg.limit,
            offset: queryArg.offset,
            start_date: queryArg.startDate,
            end_date: queryArg.endDate,
            country: queryArg.country,
            country_id: queryArg.countryId,
            state_id: queryArg.stateId,
            district_id: queryArg.districtId
          }
        }),
        providesTags: ['matching']
      }),
      matchingWeekOverWeekPercentage: build.query<
        MatchingWeekOverWeekPercentageApiResponse,
        MatchingWeekOverWeekPercentageApiArg
      >({
        query: queryArg => ({
          url: `/matching/week_over_week/percentage/`,
          params: {
            search: queryArg.search,
            ordering: queryArg.ordering,
            limit: queryArg.limit,
            offset: queryArg.offset,
            start_date: queryArg.startDate,
            end_date: queryArg.endDate,
            country: queryArg.country,
            country_id: queryArg.countryId,
            state_id: queryArg.stateId,
            district_id: queryArg.districtId
          }
        }),
        providesTags: ['matching']
      }),
      userInsightsBarGraph: build.query<
        UserInsightsBarGraphApiResponse,
        UserInsightsBarGraphApiArg
      >({
        query: queryArg => ({
          url: `/user/insights/bar_graph/`,
          params: {
            search: queryArg.search,
            ordering: queryArg.ordering,
            limit: queryArg.limit,
            offset: queryArg.offset,
            status_history: queryArg.statusHistory,
            start_date: queryArg.startDate,
            end_date: queryArg.endDate,
            country: queryArg.country,
            country_id: queryArg.countryId,
            state_id: queryArg.stateId,
            district_id: queryArg.districtId
          }
        }),
        providesTags: ['user']
      }),
      userInsightsPieChart: build.query<
        UserInsightsPieChartApiResponse,
        UserInsightsPieChartApiArg
      >({
        query: queryArg => ({
          url: `/user/insights/pie_chart/`,
          params: {
            search: queryArg.search,
            ordering: queryArg.ordering,
            limit: queryArg.limit,
            offset: queryArg.offset,
            trend: queryArg.trend,
            status_history: queryArg.statusHistory,
            start_date: queryArg.startDate,
            end_date: queryArg.endDate,
            country: queryArg.country,
            country_id: queryArg.countryId,
            state_id: queryArg.stateId,
            district_id: queryArg.districtId
          }
        }),
        providesTags: ['user']
      }),
      userLocationVolunteerMapList: build.query<
        UserLocationVolunteerMapListApiResponse,
        UserLocationVolunteerMapListApiArg
      >({
        query: queryArg => ({
          url: `/user/location_volunteer/map_list/`,
          params: {
            search: queryArg.search,
            ordering: queryArg.ordering,
            limit: queryArg.limit,
            offset: queryArg.offset,
            start_date: queryArg.startDate,
            end_date: queryArg.endDate,
            country: queryArg.country,
            country_id: queryArg.countryId,
            state_id: queryArg.stateId,
            district_id: queryArg.districtId
          }
        }),
        providesTags: ['user']
      }),
      userVolunteerUnpausedLineChart: build.query<
        UserVolunteerUnpausedLineChartApiResponse,
        UserVolunteerUnpausedLineChartApiArg
      >({
        query: queryArg => ({
          url: `/user/volunteer_unpaused/line_chart/`,
          params: {
            search: queryArg.search,
            ordering: queryArg.ordering,
            limit: queryArg.limit,
            offset: queryArg.offset,
            start_date: queryArg.startDate,
            end_date: queryArg.endDate,
            country: queryArg.country,
            country_id: queryArg.countryId,
            state_id: queryArg.stateId,
            district_id: queryArg.districtId
          }
        }),
        providesTags: ['user']
      })
    }),
    overrideExisting: false
  });
export { injectedRtkApi as enhancedApi };
export type MatchingAverageWaitTimeBarGraphApiResponse =
  /** status 200 Custom Response */ {
    results: {
      /** Total number of volunteers in the current range. */
      totalCount?: number;
      state?: {
        title?: string;
        value: {
          '7-30': number;
          '30_plus': number;
          below_7: number;
        };
      }[];
      zip?: {
        title?: string;
        value: {
          '7-30': number;
          '30_plus': number;
          below_7: number;
        };
      }[];
    };
  };

export type MatchingAverageWaitTimeBarGraphApiArg = {
  /** A search term. */
  search?: string;
  /** Which field to use when ordering the results. */
  ordering?: string;
  /** Number of results to return per page. */
  limit?: number;
  /** The initial index from which to return the results. */
  offset?: number;
  /** Start of the date range (YYYY-MM-DD) */
  startDate?: string;
  /** End of the date range (YYYY-MM-DD) */
  endDate?: string;
  /** Country uuid or all */
  country?: string;
  /** Country uuid */
  countryId?: string;
  /** State uuid */
  stateId?: string;
  /** District uuid */
  districtId?: string;
};
export type MatchingDeliveryInsightDeliveryStatisticsApiResponse =
  /** status 200 Custom Response */ {
    results: {
      /** Period for the statistics (e.g., NOV). */
      period?: string;
      /** Total number of people fed. */
      total_people_fed?: number;
      /** Total number of deliveries. */
      total_deliveries?: number;
      /** Total chefs who have delivered. */
      total_chefs_delivered?: number;
      /** Average people fed per delivery. */
      avg_people_fed_per_delivery?: number;
      /** Average number of deliveries per month per chef. */
      avg_per_month_per_chef?: number;
    }[];
  };
export type MatchingDeliveryInsightDeliveryStatisticsApiArg = {
  /** A search term. */
  search?: string;
  /** Which field to use when ordering the results. */
  ordering?: string;
  /** Number of results to return per page. */
  limit?: number;
  /** The initial index from which to return the results. */
  offset?: number;
  /** Trend , Week, Month, Year */
  trend?: string;
  /** Start of the date range (YYYY-MM-DD) */
  startDate?: string;
  /** End of the date range (YYYY-MM-DD) */
  endDate?: string;
  /** Country uuid or all */
  country?: string;
  /** Country uuid */
  countryId?: string;
  /** State uuid */
  stateId?: string;
  /** District uuid */
  districtId?: string;
};
export type MatchingDeliveryInsightLocationDeliveryApiResponse =
  /** status 200 Custom Response */ {
    count?: number;
    next?: string | null;
    previous?: string | null;
    results: RequestLocationRead[];
    ok?: boolean;
    message?: {
      text?: string | null;
      code?: number;
      args?: object | null;
    };
  };
export type MatchingDeliveryInsightLocationDeliveryApiArg = {
  /** A search term. */
  search?: string;
  /** Which field to use when ordering the results. */
  ordering?: string;
  /** Number of results to return per page. */
  limit?: number;
  /** The initial index from which to return the results. */
  offset?: number;
  /** Start of the date range (YYYY-MM-DD) */
  startDate?: string;
  /** End of the date range (YYYY-MM-DD) */
  endDate?: string;
  /** Country uuid or all */
  country?: string;
  /** Country uuid */
  countryId?: string;
  /** State uuid */
  stateId?: string;
  /** District uuid */
  districtId?: string;
};
export type MatchingWeekOverWeekPercentageApiResponse =
  /** status 200 Custom Response */ {
    count?: number;
    next?: string | null;
    previous?: string | null;
    results: WeekOverWeek;
    ok?: boolean;
    message?: {
      text?: string | null;
      code?: number;
      args?: object | null;
    };
  };
export type MatchingWeekOverWeekPercentageApiArg = {
  /** A search term. */
  search?: string;
  /** Which field to use when ordering the results. */
  ordering?: string;
  /** Number of results to return per page. */
  limit?: number;
  /** The initial index from which to return the results. */
  offset?: number;
  /** Start of the date range (YYYY-MM-DD) */
  startDate?: string;
  /** End of the date range (YYYY-MM-DD) */
  endDate?: string;
  /** Country uuid or all */
  country?: string;
  /** Country uuid */
  countryId?: string;
  /** State uuid */
  stateId?: string;
  /** District uuid */
  districtId?: string;
};
export type UserInsightsBarGraphApiResponse =
  /** status 200 Custom Response */ {
    results: {
      zip?: any[];
      /** Total number of volunteers in the current range. */
      totalCount?: number;
      state?: {
        /** (e.g., AZ). */
        name?: string;
        status?: {
          /** Status label (e.g., Active Volunteers). */
          label?: string;
          /** Current count of volunteers in this status. */
          value?: number;
        }[];
      }[];
      country?: {
        /** (e.g., AZ). */
        name?: string;
        status?: {
          /** Status label (e.g., Active Volunteers). */
          label?: string;
          /** Current count of volunteers in this status. */
          value?: number;
        }[];
      }[];
      district?: {
        /** (e.g., AZ). */
        name?: string;
        status?: {
          /** Status label (e.g., Active Volunteers). */
          label?: string;
          /** Current count of volunteers in this status. */
          value?: number;
        }[];
      }[];
    };
  };

export type UserInsightsBarGraphApiArg = {
  /** A search term. */
  search?: string;
  /** Which field to use when ordering the results. */
  ordering?: string;
  /** Number of results to return per page. */
  limit?: number;
  /** The initial index from which to return the results. */
  offset?: number;
  /** Status History */
  statusHistory?: boolean;
  /** Start of the date range (YYYY-MM-DD) */
  startDate?: string;
  /** End of the date range (YYYY-MM-DD) */
  endDate?: string;
  /** Country uuid or all */
  country?: string;
  /** Country uuid */
  countryId?: string;
  /** State uuid */
  stateId?: string;
  /** District uuid */
  districtId?: string;
};
export type UserInsightsPieChartApiResponse =
  /** status 200 Custom Response */ {
    results: {
      /** Total number of volunteers in the current range. */
      totalCount?: number;
      /** Percentage growth in total volunteers. */
      growth?: number;
      /** Trend of total volunteers ('up', 'down', 'no change'). */
      trend?: string;
      /** List of volunteer statuses with their details. */
      status?: {
        /** Status label (e.g., Active Volunteers). */
        label?: string;
        /** Current count of volunteers in this status. */
        value?: number;
        /** Percentage growth for this status. */
        growth?: number;
        /** Trend for this status ('up', 'down', 'no change'). */
        trend?: string;
        /** Color associated with this status. */
        color?: string;
      }[];
    };
  };
export type UserInsightsPieChartApiArg = {
  /** A search term. */
  search?: string;
  /** Which field to use when ordering the results. */
  ordering?: string;
  /** Number of results to return per page. */
  limit?: number;
  /** The initial index from which to return the results. */
  offset?: number;
  /** Trend , Week, Month, Year */
  trend?: string;
  /** Status History */
  statusHistory?: boolean;
  /** Start of the date range (YYYY-MM-DD) */
  startDate?: string;
  /** End of the date range (YYYY-MM-DD) */
  endDate?: string;
  /** Country uuid or all */
  country?: string;
  /** Country uuid */
  countryId?: string;
  /** State uuid */
  stateId?: string;
  /** District uuid */
  districtId?: string;
};
export type UserLocationVolunteerMapListApiResponse =
  /** status 200 Custom Response */ {
    count?: number;
    next?: string | null;
    previous?: string | null;
    results: UserLocationRead[];
    ok?: boolean;
    message?: {
      text?: string | null;
      code?: number;
      args?: object | null;
    };
  };
export type UserLocationVolunteerMapListApiArg = {
  /** A search term. */
  search?: string;
  /** Which field to use when ordering the results. */
  ordering?: string;
  /** Number of results to return per page. */
  limit?: number;
  /** The initial index from which to return the results. */
  offset?: number;
  /** Start of the date range (YYYY-MM-DD) */
  startDate?: string;
  /** End of the date range (YYYY-MM-DD) */
  endDate?: string;
  /** Country uuid or all */
  country?: string;
  /** Country uuid */
  countryId?: string;
  /** State uuid */
  stateId?: string;
  /** District uuid */
  districtId?: string;
};
export type UserVolunteerUnpausedLineChartApiResponse =
  /** status 200 Custom Response */ {
    count?: number;
    next?: string | null;
    previous?: string | null;
    results: AggregatedData[];
    ok?: boolean;
    message?: {
      text?: string | null;
      code?: number;
      args?: object | null;
    };
  };
export type UserVolunteerUnpausedLineChartApiArg = {
  /** A search term. */
  search?: string;
  /** Which field to use when ordering the results. */
  ordering?: string;
  /** Number of results to return per page. */
  limit?: number;
  /** The initial index from which to return the results. */
  offset?: number;
  /** Start of the date range (YYYY-MM-DD) */
  startDate?: string;
  /** End of the date range (YYYY-MM-DD) */
  endDate?: string;
  /** Country uuid or all */
  country?: string;
  /** Country uuid */
  countryId?: string;
  /** State uuid */
  stateId?: string;
  /** District uuid */
  districtId?: string;
};
export type RequestLocation = {
  lat?: number | null;
  lon?: number | null;
};
export type RequestLocationRead = {
  uuid?: string;
  lat?: number | null;
  lon?: number | null;
  type?: string;
};
export type InsightNestedLabel = {
  current_week: string;
  percentage_change: number;
};
export type InsightNested = {
  title: string;
  values: InsightNestedLabel[];
};

export type WeekOverWeek = {
  totalCount: number;
  zip?: InsightNested[];
  district?: InsightNested[];
  country?: InsightNested[];
  state?: InsightNested[];
};

export type UserLocation = {
  lat?: number;
  lon?: number;
};
export type UserLocationRead = {
  uuid?: string;
  lat?: number;
  lon?: number;
  type?: string;
};
export type AggregatedData = {
  Jan: number;
  Feb: number;
  Mar: number;
  Apr: number;
  May: number;
  Jun: number;
  Jul: number;
  Aug: number;
  Sep: number;
  Oct: number;
  Nov: number;
  Dec: number;
};
export const {
  useMatchingAverageWaitTimeBarGraphQuery,
  useMatchingDeliveryInsightDeliveryStatisticsQuery,
  useMatchingDeliveryInsightLocationDeliveryQuery,
  useMatchingWeekOverWeekPercentageQuery,
  useUserInsightsBarGraphQuery,
  useUserInsightsPieChartQuery,
  useUserLocationVolunteerMapListQuery,
  useUserVolunteerUnpausedLineChartQuery
} = injectedRtkApi;
