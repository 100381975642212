import { useTranslation } from 'react-i18next';

export const Footer = () => {
  const { t } = useTranslation();

  return (
    <>
      <footer className="copyright-row fixed mobile-footer  text-[--na-secondary-color] bottom-2 text-center px-10 md:pb-0 md:text-left mt-2 text-xs w-full flex justify-between md:text-normal overflow-x-hidden">
        <div className="flex pl-3 md:pl-0">
          <a
            role="none"
            data-testid="privacy-policy"
            className="cursor-pointer"
            href="/privacy-policy"
            target="_blank">
            {t('privacyPolicy')}
          </a>{' '}
          | {t('copyRight')} © 2025
        </div>
        <div className="z-50 md:text-right text-center !bottom-1 text-sm md:bg-transparent ">
          V 1.10.0.62
        </div>
      </footer>
    </>
  );
};
