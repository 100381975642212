export const findTheNextItem = (array: any[], currentItem: any) => {
  const currentIndex = array.findIndex(item => item?.uuid === currentItem);

  if (currentIndex + 1 < array.length) {
    return array[currentIndex + 1];
  }
  return array[0];
};

export const getUnmatchedFromB = <T extends Record<string, any>>(
  objA: T,
  objB: T
) => {
  const diff: Partial<T> = {};
  const extractUUIDs = (arr: any[]): string[] =>
    arr.map(item => (typeof item === 'string' ? item : item.uuid));
  const hasArrayChanged = (arrA: any[], arrB: any[]): boolean => {
    const uuidSetA = new Set(arrA ? extractUUIDs(arrA) : []);
    const uuidSetB = new Set(arrB ? extractUUIDs(arrB) : []);

    return (
      ![...uuidSetA].every(uuid => uuidSetB.has(uuid)) ||
      ![...uuidSetB].every(uuid => uuidSetA.has(uuid))
    );
  };

  for (const key in objB) {
    if (Object.hasOwnProperty.call(objB, key)) {
      const valueA = objA[key];
      const valueB = objB[key];

      if (Array.isArray(valueB)) {
        if (!Array.isArray(valueA) || hasArrayChanged(valueA, valueB)) {
          diff[key] = valueB;
        }
      } else if (valueA !== valueB) {
        diff[key] = valueB;
      }
    }
  }

  return Object.keys(diff).length > 0 ? diff : {};
};

export const updateArray = ({
  hasChange,
  isSelected,
  uuid,
  added,
  deleted,
  setAdded,
  setDeleted
}: any) => {
  const addToSet = (prev: string[], value: string): string[] => {
    const updated = new Set(prev);
    updated.add(value);
    return Array.from(updated);
  };

  const removeFromArray = (array: any, value: any) =>
    array.filter((item: string) => item !== value);

  if (hasChange) {
    if (isSelected) {
      setAdded((prev: string[]) => addToSet(prev, uuid));
      setDeleted(removeFromArray(deleted, uuid));
    } else {
      setAdded(removeFromArray(added, uuid));
      setDeleted((prev: any) => addToSet(prev, uuid));
    }
  } else {
    setAdded(removeFromArray(added, uuid));
    setDeleted(removeFromArray(deleted, uuid));
  }
};
