import { FC, useEffect, useState } from 'react';
import { useTable } from 'react-table';
import { TableProps } from '@business/interfaces/table';
import { Images } from '@business/assets/images';
import { useTranslation } from 'react-i18next';

interface ExtendedTableProps extends TableProps {
  alternateRowColor?: boolean;
  tableBodyClassName?: string;
  classNameTr?: string;
  noDataText?: string;
  rowClickHandler?: (value: any) => void;
  noTdBackground?: boolean;
}

export const Table: FC<ExtendedTableProps> = ({
  classNameMain,
  tableData,
  classNameBody,
  tableColumns,
  classNameHead,
  className,
  initialState,
  classNameTd,
  alternateRowColor = false,
  tableBodyClassName = 'bg-white divide-y divide-gray-300',
  classNameTr,
  noDataText = 'noDataFound',
  rowClickHandler,
  noTdBackground = false
}) => {
  const [data, setData] = useState(tableData);
  const [columns, setColumns] = useState(tableColumns);
  const { t } = useTranslation();

  useEffect(() => {
    setData(tableData);
    setColumns(tableColumns);
  }, [tableData, tableColumns]);

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns, data, initialState });
  return (
    <div className={classNameMain}>
      <table {...getTableProps()} className={className}>
        <thead className="rounded-t-lg overflow-hidden">
          {headerGroups.map(headerGroup => (
            <tr
              className={classNameTr}
              key={headerGroup?.id}
              {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column, columnIndex) => (
                <th
                  key={column?.id}
                  {...column.getHeaderProps()}
                  className={`${classNameHead} ${columnIndex === 0 ? 'rounded-tl-lg' : ''} ${columnIndex === headerGroup.headers.length - 1 ? 'rounded-tr-lg' : ''}`}>
                  {column.render('Header')}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()} className={tableBodyClassName}>
          {rows.length === 0 && (
            <tr>
              <td colSpan={headerGroups[0]?.headers.length} align="center">
                <div className="p-3">
                  <img
                    src={Images.NoData}
                    height={200}
                    width={200}
                    alt="loadingIcon"
                    className="object-contain"
                  />
                  <p className="text-base text-center p-3">{t(noDataText)}</p>
                </div>
              </td>
            </tr>
          )}
          {rows.map((row, i) => {
            prepareRow(row);
            const isLastRow = i === rows.length - 1;
            const trBodyClass = noTdBackground
              ? classNameBody
              : `${classNameBody} ${isLastRow ? 'border-zip-bottom' : ''} ${
                  alternateRowColor && i % 2 !== 0
                    ? 'bg-[var(--na-primary-color-active-lite)]'
                    : 'bg-white'
                }`;

            return (
              <tr
                key={row?.id}
                {...row.getRowProps()}
                className={trBodyClass}
                onClick={() => rowClickHandler?.(row?.original)}>
                {row.cells.map(cell => {
                  return (
                    <td
                      key={cell?.value}
                      {...cell.getCellProps()}
                      // className="px-3 py-3"
                      className={classNameTd}>
                      {cell.render('Cell')}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};
