import React, { useState } from 'react';
import { DateRange, RangeKeyDict } from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import moment from 'moment';
import { SVGAssets } from '@business/assets/vectors';
import { dateFormat } from '@utils/constants/globals';
import { Button } from '../Button';

interface DateRangeState {
  startDate: Date;
  endDate: Date;
  key: string;
}

interface DateRangeFilterProps {
  handleDateRange?: any;
  selectedDateRange?: any;
}
const DateRangePicker: React.FC<DateRangeFilterProps> = ({
  handleDateRange,
  selectedDateRange
}) => {
  const [showPicker, setShowPicker] = useState(false);
  const [dateRange, setDateRange] = useState<DateRangeState>({
    startDate: new Date(selectedDateRange.split('-')[0]),
    endDate: new Date(selectedDateRange.split('-')[1]),
    key: 'selection'
  });
  const handleSelect = (ranges: RangeKeyDict) => {
    const { selection } = ranges;
    let startDate = moment(selection.startDate || new Date()).format(
      dateFormat
    );
    let endDate = moment(selection.endDate || new Date()).format(dateFormat);
    handleDateRange(`${startDate}-${endDate}`);
    setDateRange({
      startDate: selection.startDate || new Date(), // Fallback to current date if undefined
      endDate: selection.endDate || new Date(),
      key: selection.key
    });
    setShowPicker(false); // Close picker after selection
  };

  return (
    <div
      className="relative"
      id="date-range-picker"
      data-testid="date-range-picker">
      <Button
        btnStyle="link"
        onClick={() => setShowPicker(!showPicker)}
        data-testid="date-range-picker-btn"
        className="min-h-6 h-10 text-[var(--na-secondary-color-hover)]  border border-[var(--na-primary-color)] w-full text-sm font-medium p-2 mb-4 focus:outline-none px-4 py-2 rounded-lg shadow-md cursor-pointer flex justify-between items-center">
        <span data-testid="date-range-text">
          {`${moment(dateRange.startDate).format(dateFormat)} - ${moment(dateRange.endDate).format(dateFormat)}`}
        </span>
        <SVGAssets.CalenderDownIcon data-testid="calendar-icon" />
      </Button>
      {showPicker && (
        <div
          className="absolute z-10 mt-0 bg-white shadow-lg rounded-lg"
          data-testid="date-picker">
          <DateRange
            data-testid="date-range-picker"
            ranges={[dateRange]}
            onChange={handleSelect}
            rangeColors={['#FB923C']}
            minDate={moment(dateRange.startDate).subtract(1, 'year').toDate()}
            maxDate={moment(dateRange.startDate).add(1, 'year').toDate()}
          />
        </div>
      )}
    </div>
  );
};

export default DateRangePicker;
